/* Icons */
import { FaDollarSign, FaEuroSign } from 'react-icons/fa';

// CONSTANTES
/* Endpoint */
export const endpoint = process.env.REACT_APP_ENDPOINT;

/* Formato de Fecha con Hora */
export const formatTime = (date, format = '') => {
    // VARIABLES
    let year = date.getFullYear();              /* Año Actual */
    let month = (date.getMonth() + 1);          /* Mes Actual */
    let day = date.getDate();                   /* Día Actual */
    let hours = date.getHours();                /* Hora Actual */
    let minutes = date.getMinutes();            /* Minuto Actuales */
    let seconds = date.getSeconds();            /* Segundos Actuales */

    // CONDICIONAL
    /* Comprobación del Mes Actual Menor que Diez */
    if (month < 10) month = `0${month}`;

    // CONDICIONAL
    /* Comprobación del Día Actual Menor que Diez */
    if (day < 10) day = `0${day}`;

    // CONDICIONAL
    /* Comprobación de la Hora Actual Menor que Diez */
    if (hours < 10) hours = `0${hours}`;

    // CONDICIONAL
    /* Comprobación de los Minutos Actuales Menores que Diez */
    if (minutes < 10) minutes = `0${minutes}`;

    // CONDICIONAL
    /* Comprobación de los Segundos Actuales Menores que Diez */
    if (seconds < 10) seconds = `0${seconds}`;

    // VARIABLES
    let time = `${hours}:${minutes}:${seconds}`;        /* Hora */
    let fecha = '';                                     /* Fecha */

    // CONDICIONAL
    /* Comprobación del Formato Indicado para la Fecha */
    switch (format) {
        case 'date /':
            fecha = `${day}/${month}/${year}`;

            break;

        case 'datetime /':
            fecha = `${day}/${month}/${year}T${time}`;
    
            break;

        case 'input':
            fecha = `${year}-${month}-${day}`;

            break;

        default:
            fecha = `${year}-${month}-${day}T${time}`;

            break;
    }

    // RETORNO
    return fecha;
}

/* Extensiones Válidas para los Documentos Asociados a una Factura */
export const extensions = [
    { bgColor: 'bg-ruby', label: 'pdf', type: 'application/pdf', },
    { bgColor: 'bg-blue', label: 'doc', type: 'application/msword', },
    { bgColor: 'bg-blue', label: 'docx', type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', },
    { bgColor: 'bg-emerald', label: 'xls', type: 'application/vnd.ms-excel', },
    { bgColor: 'bg-emerald', label: 'xlsx', type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', },
    { bgColor: 'bg-orange-500', label: 'jpg', type: 'image/jpg', },
    { bgColor: 'bg-orange-500', label: 'jpeg', type: 'image/jpeg', },
    { bgColor: 'bg-orange-500', label: 'png', type: 'image/png', },
]

/* Imágenes */
export const images = {
    background1: require('../assets/background1.jpg'),
    dolosLavander: require('../assets/dolos-lavander.png'),
    firma: require('../assets/firma.png'),
    logo: require('../assets/logo.png'),
    gonavi: require('../assets/gonavi.png'),
    sello1: require('../assets/sello1.png'),
    sello2: require('../assets/sello2.png'),
    selloMini1: require('../assets/sello1-mini.png'),
    selloMini2: require('../assets/sello2-mini.png'),
    userMing: require('../assets/user-ming.png'),
    userWhite: require('../assets/user-white.png'),
}

/* Valores Numéricos de los Meses */
const months = [
    { month: 'enero', value: '01', },
    { month: 'febrero', value: '02', },
    { month: 'marzo', value: '03', },
    { month: 'abril', value: '04', },
    { month: 'mayo', value: '05', },
    { month: 'junio', value: '06', },
    { month: 'julio', value: '07', },
    { month: 'agosto', value: '08', },
    { month: 'septiembre', value: '09', },
    { month: 'octubre', value: '10', },
    { month: 'noviembre', value: '11', },
    { month: 'diciembre', value: '12', },
]

/* Nombres Corporativos */
export const nombresCorp = [
    { id: 1, label: 'Importadora y Transporte Gonavi C.A.', },
    { id: 2, label: 'Importadora y Transporte Gonavi Corp.', },
    { id: 3, label: 'Importadora y Transporte Gonavi S.L.', },
]

/* Tipos de Divisas */
export const tiposDivisas = [
    { label: 'Dólar - $', value: 'dolar', },
    { label: 'Euro - €', value: 'euro', },
]

/* Opciones del Toast */
export const toastConfig = {
    autoClose: 5000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: 'top-right',
    theme: 'colored',
}

// EXPRESIONES REGULARES
export const regexEmail = new RegExp(/[\w\d-]+@[\w\d-]+\.[\w\d-]+/g);           /* Email */
export const regexIdFiscal = new RegExp(/^([A-Za-z]+)-([0-9]+)$/);              /* Identificación Fiscal */
export const regexNum = new RegExp(/^([0-9])*$/);                               /* Solo Números */
export const regexNumDec = new RegExp(/^([0-9]+,?([0-9]{1,2})?)$/);             /* Solo Números con Dos Decimales */

// FUNCIONES
/* Agregación de Ceros a un Número */
export const addTrailingZeros = (num, totalLength, start = false) => {
    // CONDICIONAL
    /* Comprobación de la Agregación al Principio del Número */
    if (start) return String(num).padStart(totalLength, '0');
    else return String(num).padEnd(totalLength, '0');
}

/* Determinación de la Dirección Correspondiente */
export const getAdress = (fecha) => {
    // VARIABLES
    let month = months.find((item) => item.month === fecha[0]);                     /* Valor Numérico del Mes de la Fecha */
    let facturaDate = new Date(`${fecha[2]}/${month.value}/${fecha[1]}`);           /* Fecha para la Comprobación */
    let lastDate = new Date('2024/02/25');                                          /* Última Fecha de la Dirección Antigua */

    // RETORNO
   return facturaDate > lastDate;
}

/* Determinación del Presidente de Bolipuestos Correspondiente */
export const getPresident = (fecha) => {
    // VARIABLES
    let certificacionDate = new Date(fecha.replace(/-/g, '-'));               /* Fecha para la Comprobación */
    let lastDate = new Date('2024/09/18');                                    /* Última Fecha del Antiguo Presidente */

    // RETORNO
   return certificacionDate > lastDate;
}

/* Determinación de la Dirección Correspondiente */
export const getStamp = (year, month, isFactura = true) => {
    // CONDICIONAL
    /* Comprobación del Uso para una Factura */
    if (isFactura) {
        // VARIABLES
        /* Valor Numérico del Mes de la Fecha */
        let numMonth = months.find((item) => item.month === month);

        // CONDICIOANL
        /* Comprobación del Año Igual o Mayor a 2024 y el Mes Igual o Mayor que 3 */
        if (Number(year) >= 2024 && Number(numMonth) >= 3) return images.selloMini2;
        else return images.selloMini1;
    }
    else {
        // CONDICIOANL
        /* Comprobación del Año Igual o Mayor a 2024 y el Mes Igual o Mayor que 3 */
        if (Number(year) >= 2024 && Number(month) >= 3) return images.sello2;
        else return images.sello1;
    }
}

/* Saludo de Bienvenida */
export const getGreeting = () => {
    // VARIABLES
    /* Hora Actual */
    let horas = new Date().getHours();

    // CONDICIONAL
    /* Comprobación de la Hora Actual Mayor que Cuatro y Menor o Igual que Doce */
    if (horas > 4 && horas <= 12) return 'Buenos días';
    
    // CONDICIONAL
    /* Comprobación de la Hora Actual Mayor que Doce y Menor o Igual que Diecinueve */
    if (horas > 12 && horas <= 19) return 'Buenas tardes';
    
    // RETORNO
    return 'Buenas noches';
}

/* Obtención del Icono para el Tipo de Divisa */
export const iconDivisa = (tipo, className = 'w-4 h-4') => {
    // VARIABLES
    /* Icono */
    let icon;

    // CONDICIONAL
    /* Comprobación del Tipo de Divisa */
    switch (tipo) {
        case 'dolar':
            icon = <FaDollarSign className={`${className} text-emerald`} />;
            break;

        case 'euro':
            icon = <FaEuroSign className={`${className} text-amber-400`} />
            break;

        default:
            break;
    }

    // RETORNO
    return icon;
}

/* Obtención de la Descripción para el Tipo de Divisa */
export const descripDivisa = (tipo) => {
    // VARIABLES
    /* Descripción */
    let descrip;

    // CONDICIONAL
    /* Comprobación del Tipo de Divisa */
    switch (tipo) {
        case 'dolar':
            descrip = 'DOLARES AMERICANOS';
            break;

        case 'euro':
            descrip = 'EUROS';
            break;

        default:
            break;
    }

    // RETORNO
    return descrip;
}

/* Ordenamiento de un Resultado de un Response */
export const orderDesc = (array) => array.sort((a, b) => {
    // CONDICIONAL
    /* Comprobación del id del Primer Argumento Mayor que el id del Segundo Argumento */
    if (a.id > b.id) return -1;

    // CONDICIONAL
    /* Comprobación del id del Primer Argumento Menor que el id del Segundo Argumento */
    if (a.id < b.id) return 1;

    // RETORNO
    return 0;
});

/* Obtención de un Rango de Elementos de un Arreglo */
export const range = (start, end) => {
    // VARIABLES
    /* Tamaño del Rango */
    let length = end - start + 1;

    // RETORNO
    return Array.from({ length }, (_, index) => index + start);
}

/* Formato de Números con Coma como Separador Decimal y Punto como Separador de Miles */
export const setFormatNum = (number) => new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(number);
