/* Router */
import { Link } from 'react-router-dom';
/* Components */
import { CobranzaMenu } from './menus/CobranzaMenu';
import { UserMenu } from './menus/UserMenu';
/* Icons */
import { MenuIcon, XIcon } from '@heroicons/react/outline';
/* Utils */
import { images } from '../utils/helpers';
import { navbarLinks } from '../utils/navigation';
/* Headless UI */
import { Disclosure } from '@headlessui/react';

// COMPONENTE
/* Link para la Barra de Navegación */
const NavbarLinkButton = ({ icon, title }) => (
    <Link className={`px-3 py-2 inline-flex items-center group bg-white rounded-md text-ming-light text-sm font-medium hover:bg-ming-lighter hover:text-blue-darker`} to={`/${title.toLowerCase()}`}>
        { icon } { title }
    </Link>
);

// COMPONENTE
/* Barra de Navegación */
export const Navbar = ({ data, handleLogout }) => {
    // CONSTANTES
    /* Nuevo Arreglo de los Links para la Barra de Navegación */
    const resultLinks = [];

    navbarLinks.forEach((link, index) => resultLinks.push(
        <div className="top-16 text-right" key={index}>
            <div className="relative inline-block text-left">
                <NavbarLinkButton icon={link.icon} title={link.title} />
            </div>
        </div>
    ));

    // RETORNO
    return (<div className="print:hidden">
        <Disclosure as='nav' className='bg-blue-darker'>
            {({ open }) => (
                <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    {/* Escritorio (Inicio) */}
                    <div className="h-16 flex items-center justify-between">
                        <div className="flex items-center">
                            {/* Logo */}
                            <div className="flex-shrink-0">
                                <Link to='/'>
                                    <img
                                        alt="Usuario"
                                        className="w-10 h-auto"
                                        src={images.dolosLavander}
                                    />
                                </Link>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    {/* Menu de Cobranza */}
                                    <CobranzaMenu />

                                    {/* Links de la Barra de Navegación */
                                    resultLinks }
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block">
                            <div className="ml-4 flex items-center md:ml-6">
                                {/* Menú de Usuario */}
                                <UserMenu data={data} handleLogout={handleLogout} />
                            </div>
                        </div>

                        {/* Botón de Apertura del Menú (Móvil) */}
                        <div className="-mr-2 flex md:hidden">
                            <Disclosure.Button className='p-2 bg-ming-light inline-flex items-center justify-center rounded-md text-blue-darker focus:ring-none focus:outline-none'>
                                <span className="sr-only">Menu principal</span>

                                {// CONDICIONAL
                                /* Comprobación del Estado de Apertura (Móvil) */
                                open 
                                    ? (<XIcon aria-hidden='true' className='block w-6 h-6' />) 
                                    : (<MenuIcon aria-hidden='true' className='block w-6 h-6' />)
                                }
                            </Disclosure.Button>
                        </div>
                    </div>
                    {/* Escritorio (Fin) */}
                </div>
            )}
        </Disclosure>
    </div>);
}
