/* Router */
import { Link } from 'react-router-dom';

// COMPONENTE
/* Botón */
export const TextIconButton = ({ bgColor = "bg-lavander", bgColorHover = "hover:bg-lavander-dark", borderAndColor, disabled = false, icon, margin = "m-2", onClick = () => {}, padding = "px-4 py-0.5", path = "", text = "", textColor = "text-white", textColorHover = "hover:text-white", textSize = "text-sm", type = "button", useLink = false, }) => (<>
    {// CONDICIONAL
    /* Comprobación del Uso de Link en el Botón */
    useLink 
        ? (<Link to={path}>
            <button className={`${padding} ${margin} inline-flex items-center justify-center ${bgColor} ${borderAndColor} rounded-full shadow-lg ${textColor} ${textSize} font-semibold transition duration-500 ${bgColorHover} ${textColorHover} focus:outline-none`} type="button">
                {text} {icon}
            </button>
        </Link>) 
        : (<button
            className={`${padding} ${margin} inline-flex items-center justify-center ${bgColor} ${borderAndColor} rounded-full shadow-lg ${textColor} ${textSize} font-semibold transition duration-500 ${bgColorHover} ${textColorHover} focus:outline-none`}
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {text} {icon}
        </button>)
    }
</>);
